<script setup lang="ts">
const auth = useAuth();
const bgImage = computed(() => `url(${auth.uiConfig.backgroundImage})`);
</script>

<template>
  <div class="d-flex align-center justify-center py-md-4">
    <v-container class="container">
      <slot />
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.d-flex.align-center.justify-center {
  @media (min-width: $bpSm) {
    min-height: 100dvh;
    background: v-bind(bgImage) no-repeat center center fixed;
    background-size: cover;
  }
  :deep(a, .link, span[role='button']) {
    text-decoration: underline !important;
  }
}
.container {
  padding: 0;
  height: 100%;
  min-height: calc(100dvh - 2rem);
  @media (min-width: $bpSm) {
    height: auto;
    max-width: 25rem;
    min-height: 0;
    border-radius: 0.75rem;
  }
}
</style>

<style lang="scss">
.auth-form {
  padding: 0rem 0.2rem;
  position: relative;
  overflow: hidden;
  border-radius: 0;

  > *:first-of-type {
    padding: 0.5rem 0;
    overflow: auto;
  }

  @media (max-width: 599px) {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
